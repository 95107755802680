import React from 'react';

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://www.facebook.com/jitmachinecorp"
            className="icon alt fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>Just in Time Machine Corp</li>
      </ul>
    </div>
  </footer>
);

export default Footer;
