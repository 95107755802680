import React from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

const ContactUs = () => {
  return (
    <section id="contact">
      <div className="inner">
        <ContactForm />
        <ContactInfo />
      </div>
    </section>
  );
};

export default ContactUs;
