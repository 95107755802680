import React from 'react';

class ContactForm extends React.Component {
  render() {
    return (
      <section>
        <h3>
          To submit an RFQ or general inquiry, please email{' '}
          <a href="mailto:inquiries@jitmachinecorp.com">
            inquiries@jitmachinecorp.com
          </a>
        </h3>
      </section>
    );
  }
}

export default ContactForm;
