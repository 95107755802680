import React from 'react';

const ContactInfo = () => {
  return (
    <section className="split">
      <section>
        <div className="contact-method">
          <span className="icon alt fa-envelope" />
          <h3>Email</h3>
          <a href="mailto:inquires@jitmachinecorp.com">
            inquires@jitmachinecorp.com
          </a>
        </div>
      </section>
      <section>
        <div className="contact-method">
          <span className="icon alt fa-phone" />
          <h3>Phone</h3>
          <span>+1 (608) 205-8608</span>
        </div>
      </section>
      <section>
        <div className="contact-method">
          <span className="icon alt fa-home" />
          <h3>Address</h3>
          <span>
            7306 US Hwy 14
            <br />
            Middleton, WI 53562
            <br />
            USA
          </span>
        </div>
      </section>
    </section>
  );
};

export default ContactInfo;
